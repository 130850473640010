import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
var DEFAULT_ENVIRONMENT = {
    clientHeight: 480,
    clientWidth: 640,
    fogColor: 0xFFFFFF,
    lightIntensity: 1,
    sceneColor: 0xFAFDFE,
    skyColor: 0xF7FBFB,
    groundColor: 0x9EA4A7,
};
export var createCamera = function (props) {
    console.log(': createCamera');
    var _a = props.clientHeight, clientHeight = _a === void 0 ? DEFAULT_ENVIRONMENT.clientHeight : _a, _b = props.clientWidth, clientWidth = _b === void 0 ? DEFAULT_ENVIRONMENT.clientWidth : _b;
    var myCamera = new THREE.PerspectiveCamera(30, clientWidth / clientHeight, 1, 5000);
    myCamera.position.set(0, 2, 20);
    return myCamera;
};
export var createScene = function (props) {
    if (props === void 0) { props = {}; }
    console.log(': createScene');
    var _a = props.color, color = _a === void 0 ? DEFAULT_ENVIRONMENT.sceneColor : _a, _b = props.fog, fog = _b === void 0 ? DEFAULT_ENVIRONMENT.fogColor : _b;
    var myScene = new THREE.Scene();
    myScene.background = new THREE.Color().setHex(color);
    myScene.fog = new THREE.Fog(fog, 1, 5000);
    return myScene;
};
export var createRenderer = function (props) {
    if (props === void 0) { props = {}; }
    console.log(': createRenderer');
    var _a = props.clientHeight, clientHeight = _a === void 0 ? DEFAULT_ENVIRONMENT.clientHeight : _a, _b = props.clientWidth, clientWidth = _b === void 0 ? DEFAULT_ENVIRONMENT.clientWidth : _b;
    var myRenderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true, antialias: true });
    myRenderer.autoClear = true;
    myRenderer.setPixelRatio(window.devicePixelRatio);
    myRenderer.setSize(clientWidth, clientHeight);
    myRenderer.shadowMap.enabled = true;
    myRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
    return myRenderer;
};
export var createControls = function (props) {
    console.log(': createControls');
    var camera = props.camera, renderer = props.renderer;
    var myControls = new OrbitControls(camera, renderer.domElement);
    myControls.autoRotateSpeed = 6;
    myControls.enableDamping = false;
    myControls.enableKeys = false;
    myControls.enablePan = false;
    myControls.enableZoom = true;
    myControls.maxDistance = 300;
    myControls.minDistance = 10;
    myControls.screenSpacePanning = false;
    myControls.target = new THREE.Vector3(0, 1.5, 0);
    myControls.mouseButtons = {
        LEFT: THREE.MOUSE.LEFT,
        MIDDLE: THREE.MOUSE.MIDDLE,
        RIGHT: THREE.MOUSE.RIGHT,
    };
    return myControls;
};
export var createLight = function (props) {
    if (props === void 0) { props = {}; }
    var _a = props.intensity, intensity = _a === void 0 ? DEFAULT_ENVIRONMENT.lightIntensity : _a, _b = props.skyColor, skyColor = _b === void 0 ? DEFAULT_ENVIRONMENT.skyColor : _b, _c = props.groundColor, groundColor = _c === void 0 ? DEFAULT_ENVIRONMENT.groundColor : _c;
    var atmosphereLight = new THREE.HemisphereLight(skyColor, groundColor, intensity);
    var directionalLight = new THREE.DirectionalLight(0xffffff, intensity / 2);
    directionalLight.position.y = 50;
    directionalLight.position.x = 100;
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 1024 * 4;
    directionalLight.shadow.mapSize.height = 1024 * 4;
    directionalLight.shadow.radius = 1;
    directionalLight.shadow.bias = 0.00001;
    return [atmosphereLight, directionalLight];
};
