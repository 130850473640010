var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as THREE from 'three';
import MeshBuilder from './MeshBuilder';
var MeshFactory = /** @class */ (function (_super) {
    __extends(MeshFactory, _super);
    function MeshFactory(props) {
        if (props === void 0) { props = {}; }
        var _this = _super.call(this) || this;
        var _a = props.color, color = _a === void 0 ? undefined : _a;
        _this.color = color;
        return _this;
    }
    MeshFactory.prototype.createCube = function (size) {
        var geometry = new THREE.BoxGeometry(size, size, size);
        return this.createMesh(geometry);
    };
    MeshFactory.prototype.createSphere = function (size) {
        var geometry = new THREE.SphereGeometry(size, 32, 24);
        return this.createMesh(geometry);
    };
    MeshFactory.prototype.createSquare = function (size) {
        var geometry = new THREE.PlaneGeometry(size, size);
        var mesh = this.createMesh(geometry);
        mesh.castShadow = false;
        return mesh;
    };
    return MeshFactory;
}(MeshBuilder));
export default MeshFactory;
