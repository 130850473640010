import * as THREE from 'three';
import Assembler from './Assembler';
import Provider from './Provider';
import StrategyBuilder from './StrategyBuilder';
import PositioningMethodsPool from './PositioningMethodsPool';
import MeshFactory from './MeshFactory';
import { getRandomInt, getSize } from '../utils';
var ObjectsFactory = /** @class */ (function () {
    function ObjectsFactory() {
    }
    ObjectsFactory.prototype.cubePositions = function (objects) {
        var options = {
            count: objects.length,
            partCubeSize: objects[0].userData.size,
            iteratorX: 0,
            iteratorY: 0,
            iteratorZ: 0,
        };
        var strategyBuilder = new StrategyBuilder(objects);
        strategyBuilder.setOptions(options);
        strategyBuilder.setLoopMethod(PositioningMethodsPool.cubesMethod);
        strategyBuilder.strategy();
    };
    ObjectsFactory.prototype.getLocationPlane = function () {
        var plane = new MeshFactory({ color: 0xEEEEEE }).createSquare(10);
        plane.rotateX(THREE.MathUtils.degToRad(90));
        plane.position.y -= 0.01;
        return plane;
    };
    Object.defineProperty(ObjectsFactory.prototype, "finalObject", {
        get: function () {
            var cubeBuilder = function () { return new MeshFactory().createCube(getRandomInt(25) / 100); };
            var assembler = new Assembler({
                parts: new Provider(cubeBuilder).getParts(125),
                strategy: this.cubePositions,
            });
            var finalModel = new THREE.Group();
            var product = assembler.product;
            var size = getSize(product);
            product.position.y += size.y / 2;
            finalModel.add(this.getLocationPlane());
            finalModel.add(product);
            return finalModel;
        },
        enumerable: false,
        configurable: true
    });
    return ObjectsFactory;
}());
export default ObjectsFactory;
