import { useState } from 'react';
import { createCamera, createControls, createRenderer, createScene } from './environment';
var universalHook = function (_a) {
    var builder = _a.builder;
    var _b = useState(), state = _b[0], setState = _b[1];
    if (!state) {
        var envObject = builder();
        setState(envObject);
        return envObject;
    }
    return state;
};
export var envHooks = {
    useCamera: function (props) { return universalHook({
        builder: function () { return createCamera(props); },
    }); },
    useScene: function (props) { return universalHook({
        builder: function () { return createScene(props); },
    }); },
    useRenderer: function (props) { return universalHook({
        builder: function () { return createRenderer(props); },
    }); },
    useControls: function (props) { return universalHook({
        builder: function () { return createControls(props); },
    }); },
};
