import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import './index.scss';
var PictureCard = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var imgURL = props.imgURL, htmlURL = props.htmlURL, title = props.title, date = props.date, views = props.views, likes = props.likes, dimensions = props.dimensions;
    var imgClasses = useMemo(function () { return classNames({
        'picture-card__image__picture': true,
        'display': !isLoading,
    }); }, [isLoading]);
    var imgLoaderClasses = useMemo(function () { return classNames({
        'picture-card__image__loader': true,
        'display': !isLoading,
    }); }, [isLoading]);
    var handleOnLoad = useCallback(function () {
        setTimeout(function () { return setIsLoading(false); }, 1);
    }, [setIsLoading]);
    return (React.createElement("div", { className: "picture-card" },
        React.createElement("h3", { className: "picture-card__title" }, title),
        React.createElement("div", { className: "picture-card__desc" },
            React.createElement("span", null, date),
            React.createElement("div", { className: "picture-card__stats" },
                views && React.createElement("span", { className: "picture-card__stats__views" }, views),
                likes && React.createElement("span", { className: "picture-card__stats__likes" }, likes))),
        React.createElement("div", { className: "picture-card__img-wrapper" },
            React.createElement("div", { className: "picture-card__image" },
                React.createElement("span", { className: imgLoaderClasses },
                    React.createElement("p", null, "Loading...")),
                React.createElement("a", { href: htmlURL },
                    React.createElement("img", { className: imgClasses, height: dimensions.height / 2, onLoad: function () { return handleOnLoad(); }, src: imgURL, width: dimensions.width / 2 }))))));
};
export default PictureCard;
