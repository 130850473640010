import React, { useEffect, useMemo, useRef } from 'react';
import { envHooks } from './environment';
import { createGridHelper, createOrdHelper } from './helpers';
import classNames from 'classnames';
import './Viewer.scss';
import { createLight } from './environment/environment';
import { getRandomInt } from './utils';
var clientSize = { clientWidth: 620, clientHeight: 480 };
var Viewer = function (_a) {
    var onSceneReady = _a.onSceneReady, className = _a.className, object3D = _a.object3D, addHelpers = _a.addHelpers;
    var animationID = 0;
    var scene = envHooks.useScene({});
    var camera = envHooks.useCamera(clientSize);
    var renderer = envHooks.useRenderer(clientSize);
    var controls = envHooks.useControls({ camera: camera, renderer: renderer });
    var mount = useRef(null);
    var init = function () {
        var _a;
        if (mount.current) {
            (_a = mount.current) === null || _a === void 0 ? void 0 : _a.appendChild(renderer.domElement);
            controls.update();
            var lights = createLight();
            lights.forEach(function (light) { return scene.add(light); });
            scene.add(object3D);
            if (addHelpers) {
                var helper = createGridHelper();
                var ordHelper = createOrdHelper();
                scene.add(ordHelper);
                scene.add(helper);
            }
            onSceneReady();
        }
    };
    var animationRunner = function () {
        var object = scene.getObjectByName('finalModel');
        if (object) {
            object.rotateX(0.01);
            object.rotateY(0.01);
            object.rotateZ(0.01);
            object.children.forEach(function (child) {
                child.rotateX(getRandomInt(10) / 100);
                child.rotateY(getRandomInt(10) / 100);
                child.rotateZ(getRandomInt(10) / 100);
            });
        }
    };
    var animate = function () {
        animationID = requestAnimationFrame(animate);
        animationRunner();
        renderer.render(scene, camera);
    };
    useEffect(function onDidMount() {
        console.log('(!) Viewer on did Mount');
        init();
        animate();
        return function () {
            cancelAnimationFrame(animationID);
        };
    }, []);
    var classes = useMemo(function () {
        var _a;
        return classNames((_a = {},
            _a[className] = Boolean(className),
            _a));
    }, [className]);
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: "three-root", id: "three-root", ref: mount })));
};
export default Viewer;
