var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import PictureCard from '../PictureCard/pictureCard';
import FEED from '../../data/dribbble-portfolio.json';
import { initialFeedFill, DEFAULT_PORTION } from './utils';
import './index.scss';
var PicturesFeed = function () {
    var _a = useState(DEFAULT_PORTION), lastIndex = _a[0], setLastIndex = _a[1];
    var _b = useState(initialFeedFill()), feed = _b[0], setFeed = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    useEffect(function onGetNewPortion() {
        var portion = [];
        var i = lastIndex;
        for (i; i < lastIndex + DEFAULT_PORTION; i++) {
            if (i < FEED.length) {
                portion.push(FEED[i]);
            }
            else
                break;
        }
        setLastIndex(i);
        setFeed(__spreadArray(__spreadArray([], feed, true), portion, true));
        setIsLoading(false);
        return function () {
            setFeed(initialFeedFill());
            setLastIndex(DEFAULT_PORTION);
            setIsLoading(false);
        };
    }, [isLoading]);
    var isEverythingLoaded = useMemo(function () {
        return lastIndex === FEED.length;
    }, [lastIndex, FEED]);
    var handleScroll = useCallback(function (e) {
        var bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100;
        if (bottom && !isEverythingLoaded) {
            setIsLoading(true);
        }
    }, [setFeed, isEverythingLoaded]);
    var renderFeed = useMemo(function () {
        return feed.map(function (picture, index) {
            if (!picture || (picture && !picture.fileName)) {
                return null;
            }
            return (React.createElement(PictureCard, { date: picture.date, dimensions: { width: picture.width, height: picture.height }, htmlURL: picture.htmlURL, imgURL: "portfolio/".concat(picture.fileName), key: "picture-".concat(index), title: picture.title }));
        });
    }, [feed]);
    return (React.createElement("div", { className: "feed-scroll-wrapper", onScroll: function (e) { return handleScroll(e); } },
        React.createElement("div", { className: "pictures-feed" }, renderFeed)));
};
export default PicturesFeed;
