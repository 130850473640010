import { getRandomHexColor, getSize } from '../utils';
import * as THREE from 'three';
var MeshBuilder = /** @class */ (function () {
    function MeshBuilder() {
    }
    MeshBuilder.createDefaultMaterial = function (color) {
        if (color === void 0) { color = getRandomHexColor(); }
        return new THREE.MeshStandardMaterial({ color: color, side: THREE.DoubleSide });
    };
    MeshBuilder.prototype.createMesh = function (geometry) {
        var material = MeshBuilder.createDefaultMaterial(this.color);
        var mesh = new THREE.Mesh(geometry, material);
        mesh.userData.size = getSize(mesh);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        return mesh;
    };
    return MeshBuilder;
}());
export default MeshBuilder;
