import * as THREE from 'three';
var StrategyBuilder = /** @class */ (function () {
    function StrategyBuilder(objects) {
        this.objects = objects;
    }
    StrategyBuilder.prototype.loop = function () {
        var _this = this;
        var loopMethod = this.loopMethod;
        if (loopMethod) {
            this.objects.forEach(function (object) { return loopMethod(object, _this.options); });
        }
        else {
            throw new Error('loop method is not defined');
        }
    };
    StrategyBuilder.prototype.applyPositionVector = function (positionVector, object) {
        var x = positionVector.x, y = positionVector.y, z = positionVector.z;
        /*
        x += object.userData.size.x / 2;
        z += object.userData.size.z / 2;
        y += object.userData.size.y / 2;
        */
        var position = new THREE.Vector3(x, y, z);
        object.position.copy(position);
    };
    StrategyBuilder.prototype.setLoopMethod = function (method) {
        var _this = this;
        this.loopMethod = function (object) {
            var positionVector = method(_this.options);
            _this.applyPositionVector(positionVector, object);
        };
    };
    StrategyBuilder.prototype.setOptions = function (state) {
        this.options = state;
    };
    StrategyBuilder.prototype.strategy = function () {
        this.loop();
    };
    return StrategyBuilder;
}());
export default StrategyBuilder;
