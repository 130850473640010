var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import ContactIcon from '../../components/ContactIcon/contactIcon';
import { ReactSVG } from 'react-svg';
import Button from '../../components/Button/button';
// @ts-ignore
import imgLogo from '../../assets/logo.svg';
// @ts-ignore
import imgTwitter from '../../assets/twitter.svg';
// @ts-ignore
import imgFacebook from '../../assets/facebook.svg';
// @ts-ignore
import imgLinkedin from '../../assets/linkedin.svg';
// @ts-ignore
import imgDribbble from '../../assets/dribbble.svg';
// @ts-ignore
import imgInstagram from '../../assets/instagram.svg';
var ContactCard = /** @class */ (function (_super) {
    __extends(ContactCard, _super);
    function ContactCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContactCard.prototype.render = function () {
        return (React.createElement("div", { className: "home-page__card" },
            React.createElement(ReactSVG, { className: "logo", src: imgLogo }),
            React.createElement("p", null,
                "Hello! My name is Alexey Degtyarik.",
                React.createElement("br", null),
                "I\u2019m designer and front-end developer."),
            React.createElement("div", { className: "buttons" },
                React.createElement(Button, { asAnchor: true, color: "blue", href: "tg://resolve?domain=@degtyarik", shadow: true, style: "solid" }, "Telegram"),
                React.createElement(Button, { asAnchor: true, color: "green", href: "mailto:muhmundr@gmail.com", shadow: true, style: "solid" }, "Email")),
            React.createElement("div", { className: "social-networks" },
                React.createElement(ContactIcon, { hint: "Twitter", href: "https://twitter.com/LEM_ing" },
                    React.createElement(ReactSVG, { src: imgTwitter })),
                React.createElement(ContactIcon, { hint: "Facebook", href: "https://www.facebook.com/muhmundr" },
                    React.createElement(ReactSVG, { src: imgFacebook })),
                React.createElement(ContactIcon, { hint: "Linkedin", href: "https://www.linkedin.com/in/alexey-degtyarik-3a14036a/" },
                    React.createElement(ReactSVG, { src: imgLinkedin })),
                React.createElement(ContactIcon, { hint: "Dribbble", href: "https://dribbble.com/degtyarik" },
                    React.createElement(ReactSVG, { src: imgDribbble })),
                React.createElement(ContactIcon, { hint: "Instagram", href: "https://www.instagram.com/muhmundr" },
                    React.createElement(ReactSVG, { src: imgInstagram })))));
    };
    return ContactCard;
}(React.Component));
export default ContactCard;
