import FEED from '../../data/dribbble-portfolio.json';
export var DEFAULT_PORTION = 5;
export var initialFeedFill = function () {
    var count = DEFAULT_PORTION;
    var feed = [];
    for (var i = 0; i < count - 1; i++) {
        feed.push(FEED[i]);
    }
    return feed;
};
