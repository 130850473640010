import * as THREE from 'three';
export var getSize = function (obj) {
    var measuringBox = new THREE.Box3().setFromObject(obj);
    var size = new THREE.Vector3();
    measuringBox.getSize(size);
    return size;
};
export var getRandomHexColor = function () {
    return parseInt(Math.floor(Math.random() * 16777215).toString(16), 16);
};
export var getRandomInt = function (max) {
    return Math.floor(Math.random() * Math.floor(max));
};
export var toCentralise = function (object) {
    var box = new THREE.Box3().setFromObject(object);
    var center = box.getCenter(new THREE.Vector3());
    var offset = object.position.sub(center);
    object.position.copy(offset);
    var wrapper = new THREE.Object3D();
    wrapper.add(object);
    return wrapper;
};
