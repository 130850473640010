import * as React from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
// @ts-ignore
import imgCross from '../../assets/cross.svg';
import Button from '../Button/button';
import './index.scss';
var DEFAULT_PROPS = {
    title: 'New SidePanel',
    visible: false,
};
var SidePanel = function (props) {
    var _a;
    var _b = props.title, title = _b === void 0 ? DEFAULT_PROPS.title : _b, _c = props.visible, visible = _c === void 0 ? DEFAULT_PROPS.visible : _c, _d = props.className, className = _d === void 0 ? '' : _d, children = props.children, onHide = props.onHide, position = props.position;
    var panelClasses = classNames((_a = {
            'side-panel': true,
            'side-panel__visible': visible,
            'left': position === 'left',
            'right': position === 'right'
        },
        _a[className] = className,
        _a));
    var fogClasses = classNames({
        'fog': true,
        'active': visible,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: panelClasses },
            React.createElement("div", { className: "side-panel__title" },
                title,
                React.createElement(Button, { color: "white", onClick: function () { return onHide && onHide(); }, style: "line" },
                    "Hide",
                    React.createElement(ReactSVG, { className: "icon-right", src: imgCross }))),
            React.createElement("div", { className: "side-panel__content-area" }, visible && children)),
        React.createElement("div", { className: fogClasses, onClick: function () { return onHide && onHide(); } })));
};
export default SidePanel;
