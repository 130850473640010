import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import ButtonDemo from './pages/ButtonDemo';
import HomePage from './pages/HomePage';
import Scene from './pages/Scene';
import WebWorkerPage from './pages/WebWorkerPage';
import Canvas from './pages/Canvas';
import NewYear from './pages/NewYear';
import './main.scss';
export default function App() {
    return (React.createElement(Router, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/scene" },
                React.createElement(Scene, null)),
            React.createElement(Route, { path: "/canvas" },
                React.createElement(Canvas, null)),
            React.createElement(Route, { path: "/ui/btn" },
                React.createElement(ButtonDemo, null)),
            React.createElement(Route, { path: "/web-worker" },
                React.createElement(WebWorkerPage, null)),
            React.createElement(Route, { path: "/2022" },
                React.createElement(NewYear, null)),
            React.createElement(Route, { path: "/" },
                React.createElement(HomePage, null)))));
}
render(React.createElement(App, null), document.getElementById('root'));
