import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { customWhaleAnimation } from '../CustomWhaleAnimation/customWhaleAnimation';
var createCamera = function (width, height) {
    var camera = new THREE.PerspectiveCamera(30, width / height, 1, 10000);
    camera.position.z = 3000;
    return camera;
};
var createRenderer = function (width, height) {
    var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    return renderer;
};
var getAnimationLoop = function (_a) {
    var renderer = _a.renderer, camera = _a.camera, customAnimation = _a.customAnimation, mixer = _a.mixer, controls = _a.controls, scene = _a.scene;
    return function () {
        customAnimation();
        renderer.render(scene, camera);
        controls.update();
        mixer.update(0.01);
    };
};
export var Viewer = function (domElement, gltf) {
    var object3d = gltf.scene;
    var width = domElement.offsetWidth;
    var height = domElement.offsetHeight;
    var camera = createCamera(width, height);
    var scene = new THREE.Scene();
    var light = new THREE.AmbientLight();
    scene.add(light);
    scene.add(gltf.scene);
    var renderer = createRenderer(width, height);
    var controls = new OrbitControls(camera, renderer.domElement);
    controls.update();
    domElement.appendChild(renderer.domElement);
    var mixer = new THREE.AnimationMixer(object3d);
    var animationAction = mixer.clipAction(gltf.animations[0]);
    animationAction.play();
    var animation = getAnimationLoop({
        renderer: renderer,
        scene: scene,
        camera: camera,
        customAnimation: function () { return customWhaleAnimation(object3d); },
        mixer: mixer,
        controls: controls
    });
    renderer.setAnimationLoop(animation);
};
