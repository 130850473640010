import * as React from 'react';
import classNames from 'classnames';
import './index.scss';
var DEFAULT_PROPS = {
    msg: 'Contact Icon',
};
var Tooltip = function (props) {
    var _a;
    var _b = props.className, className = _b === void 0 ? '' : _b, children = props.children, _c = props.msg, msg = _c === void 0 ? DEFAULT_PROPS.msg : _c;
    var classes = classNames((_a = {
            'tooltip': true
        },
        _a[className] = className,
        _a));
    return (React.createElement("div", { className: classes },
        children,
        React.createElement("span", { className: "tooltip__text" }, msg)));
};
export default Tooltip;
