import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/button';
import Worker from './worker';
var WorkerState;
(function (WorkerState) {
    WorkerState["Waiting"] = "waiting";
    WorkerState["Loading"] = "loading";
    WorkerState["Error"] = "error";
    WorkerState["Done"] = "done";
})(WorkerState || (WorkerState = {}));
var workerInstance = new Worker();
var WebWorkerPage = function () {
    var _a = useState(WorkerState.Waiting), workerState = _a[0], setWorkerState = _a[1];
    useEffect(function onMount() {
        var messageListener = function (event) {
            console.log('OMG! This is success!');
            console.log(event.data);
            setWorkerState(WorkerState.Done);
        };
        var errorListener = function (error) {
            error.preventDefault();
            console.error("Error receiving message from worker: ".concat(error.message));
            setWorkerState(WorkerState.Error);
        };
        workerInstance.addEventListener('message', messageListener);
        workerInstance.addEventListener('error', errorListener);
        return function () {
            workerInstance.removeEventListener('message', messageListener);
            workerInstance.removeEventListener('error', errorListener);
        };
    }, []);
    var onClick = function () {
        setWorkerState(WorkerState.Loading);
        workerInstance.postMessage('[1, 2]');
        // workerInstance.postMessage([1, 2]);
        console.log('Message posted to worker');
    };
    return (React.createElement("div", null,
        React.createElement(Button, { onClick: function () { return onClick(); } }, "Click me"),
        React.createElement("div", null,
            "Worker state: ",
            workerState)));
};
export default WebWorkerPage;
