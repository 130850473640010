import * as React from 'react';
import classNames from 'classnames';
import './index.scss';
var DEFAULT_PROPS = {
    asAnchor: false,
    color: 'blue',
    disabled: false,
    shadow: false,
    style: 'solid',
};
var Button = function (props) {
    var _a;
    var _b = props.asAnchor, asAnchor = _b === void 0 ? DEFAULT_PROPS.asAnchor : _b, _c = props.className, className = _c === void 0 ? '' : _c, _d = props.disabled, disabled = _d === void 0 ? DEFAULT_PROPS.disabled : _d, _e = props.style, style = _e === void 0 ? DEFAULT_PROPS.style : _e, _f = props.shadow, shadow = _f === void 0 ? DEFAULT_PROPS.shadow : _f, _g = props.color, color = _g === void 0 ? DEFAULT_PROPS.color : _g, children = props.children, href = props.href, onClick = props.onClick, target = props.target;
    var classes = classNames((_a = {
            'button': true,
            'button--solid': style === 'solid',
            'button--line': style === 'line',
            'color__green': color === 'green',
            'color__blue': color === 'blue',
            'color__white': color === 'white',
            'disabled': disabled,
            'shadow': shadow
        },
        _a[className] = className,
        _a));
    var Node = asAnchor ? 'a' : 'button';
    return (React.createElement(Node, { className: classes, disabled: disabled, href: href, 
        // @ts-ignore
        onClick: onClick, target: target }, children));
};
export default Button;
