var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useEffect, useState } from 'react';
import Button from '../../components/Button/button';
import './index.scss';
var ButtonDemo = function () {
    var canvasRef = useRef(null);
    var _a = useState([]), shapes = _a[0], setShapes = _a[1];
    var animationFrameId = useRef();
    useEffect(function () {
        var canvas = canvasRef.current;
        if (canvas && canvas.getContext) {
            var ctx_1 = canvas.getContext('2d');
            if (ctx_1) {
                ctx_1.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
        return function () {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };
    }, []);
    var handleButtonClick = function (event) {
        if (animationFrameId.current) {
            cancelAnimationFrame(animationFrameId.current);
        }
        var canvas = canvasRef.current;
        var maxDistance = 50; // Example max distance for stopping
        var newShapes = __spreadArray([], shapes, true);
        if (canvas) {
            var rect = canvas.getBoundingClientRect();
            var x = event.clientX - rect.left;
            var y = event.clientY - rect.top;
            for (var i = 0; i < 10; i++) {
                var shapeType = ['star', 'circle'][Math.floor(Math.random() * 3)];
                var angle = Math.random() * Math.PI * 2;
                var speed = Math.random() * 2 + 1;
                var newShape = {
                    type: shapeType,
                    x: x,
                    y: y,
                    size: Math.random() * 15 + 5,
                    opacity: 1,
                    initialX: x,
                    initialY: y,
                    velocity: {
                        x: Math.cos(angle) * speed,
                        y: Math.sin(angle) * speed
                    },
                    maxDistance: maxDistance,
                };
                if (shapeType === 'circle') {
                    newShape.evolutionDistance = 50;
                }
                if (shapeType === 'star') {
                    newShape.spikes = 4;
                    newShape.innerRadius = newShape.size / 2;
                    newShape.outerRadius = newShape.size;
                }
                newShapes.push(newShape);
            }
        }
        setShapes(newShapes);
        animate();
    };
    var animate = function () {
        var canvas = canvasRef.current;
        if (!canvas)
            return;
        var ctx = canvas.getContext('2d');
        if (!ctx)
            return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var currentTime = Date.now();
        setShapes(function (currentShapes) {
            return currentShapes.flatMap(function (shape) {
                var maxDistance = shape.maxDistance || 100;
                var distance = Math.sqrt(Math.pow((shape.x - shape.initialX), 2) + Math.pow((shape.y - shape.initialY), 2));
                if (!shape.stopped && distance >= maxDistance) {
                    shape.stopped = true;
                    shape.stopTime = currentTime;
                }
                if (shape.stopped && !shape.actionStarted && (currentTime - (shape.stopTime || 0) >= 300)) {
                    shape.actionStarted = true;
                    if (shape.type === 'circle' && !shape.evolved) {
                        shape.evolved = true;
                        return __spreadArray([], Array(6), true).map(function (_, index) { return (__assign(__assign({}, shape), { size: shape.size / 3, velocity: {
                                x: Math.cos((Math.PI * 2 / 6) * index) * 2,
                                y: Math.sin((Math.PI * 2 / 6) * index) * 2,
                            }, initialX: shape.x, initialY: shape.y, maxDistance: shape.size * 1.5, opacity: 1, stopped: false, actionStarted: false })); });
                    }
                }
                // Begin fading out for all shapes after the delay post-stopping
                if (shape.actionStarted) {
                    shape.opacity = Math.max(shape.opacity - 0.02, 0); // Adjust fading speed
                }
                else if (!shape.stopped) {
                    shape.x += shape.velocity.x;
                    shape.y += shape.velocity.y;
                }
                // Draw shape if it's still visible (opacity > 0)
                if (shape.opacity > 0) {
                    drawShape(ctx, shape);
                    return [shape];
                }
                else {
                    return []; // Remove shapes that have fully faded
                }
            });
        });
        animationFrameId.current = requestAnimationFrame(animate);
    };
    var drawShape = function (ctx, shape) {
        ctx.save();
        ctx.globalAlpha = shape.opacity;
        switch (shape.type) {
            case 'star':
                ctx.fillStyle = 'rgba(255, 165, 0, 0.9)'; // Example of a yellow-orange color
                if (shape.spikes && shape.innerRadius && shape.outerRadius) {
                    drawStar(ctx, shape.x, shape.y, shape.spikes, shape.outerRadius, shape.innerRadius);
                }
                break;
            case 'circle':
                ctx.fillStyle = 'rgba(241,33,68,0.9)';
                ctx.beginPath();
                ctx.arc(shape.x, shape.y, shape.size, 0, Math.PI * 2);
                ctx.fill();
                break;
        }
        ctx.restore();
    };
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "tile", style: { position: 'relative' } },
            React.createElement("canvas", { className: "animation-canvas", height: "160", ref: canvasRef, width: "260" }),
            React.createElement(Button, { color: "blue", onClick: handleButtonClick }, "Click Me"))));
};
var drawStar = function (ctx, cx, cy, spikes, outerRadius, innerRadius) {
    var rot = Math.PI / 2 * 3;
    var x = cx;
    var y = cy;
    var step = Math.PI / spikes;
    ctx.beginPath();
    ctx.moveTo(cx, cy - outerRadius);
    for (var i = 0; i < spikes; i++) {
        x = cx + Math.cos(rot) * outerRadius;
        y = cy + Math.sin(rot) * outerRadius;
        ctx.lineTo(x, y);
        rot += step;
        x = cx + Math.cos(rot) * innerRadius;
        y = cy + Math.sin(rot) * innerRadius;
        ctx.lineTo(x, y);
        rot += step;
    }
    ctx.lineTo(cx, cy - outerRadius);
    ctx.closePath();
    ctx.fill();
};
export default ButtonDemo;
