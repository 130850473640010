import * as THREE from 'three';
import { getSize, toCentralise } from '../utils';
var Assembler = /** @class */ (function () {
    function Assembler(props) {
        var _this = this;
        this.positionStrategy = props.strategy;
        this._product = new THREE.Group();
        this._product.name = 'finalModel';
        this.parts = props.parts;
        this.parts.forEach(function (part) { return _this._product.add(part); });
    }
    Object.defineProperty(Assembler.prototype, "product", {
        get: function () {
            this.applyStrategy();
            this._product.userData.size = getSize(this._product);
            return toCentralise(toCentralise(this._product));
        },
        enumerable: false,
        configurable: true
    });
    Assembler.prototype.setStrategy = function (strategy) {
        this.positionStrategy = strategy;
    };
    Assembler.prototype.applyStrategy = function () {
        if (this.positionStrategy) {
            this.positionStrategy(this.parts);
        }
        else {
            throw new Error('Strategy is not defined');
        }
    };
    return Assembler;
}());
export default Assembler;
