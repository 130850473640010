import * as React from 'react';
import classNames from 'classnames';
import Tooltip from '../Tooltip/tooltip';
import './index.scss';
var DEFAULT_PROPS = {
    hint: 'Contact Icon',
};
var ContactIcon = function (props) {
    var _a;
    var _b = props.className, className = _b === void 0 ? '' : _b, children = props.children, _c = props.hint, hint = _c === void 0 ? DEFAULT_PROPS.hint : _c, href = props.href;
    var classes = classNames((_a = {
            'contact-icon': true
        },
        _a[className] = className,
        _a));
    return (React.createElement("a", { className: classes, href: href },
        React.createElement(Tooltip, { msg: hint },
            React.createElement("span", { className: "contact-icon__icon" }, children))));
};
export default ContactIcon;
