// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/heart.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/eye.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".flex{display:flex}.flex.vertical-align{align-items:center}.inline{display:inline-block}.margin-right__5{margin-right:5px}.margin-right__10{margin-right:10px}h1,h2,h3,h4{font-weight:300}h3{font-size:18px}h2{font-size:24px}h2.white{color:#fff}body{background-color:#fafdfe;color:#9ea4a7;font-family:\"Roboto\",sans-serif;font-size:14px;margin:0;overflow:hidden}*{margin:0}.picture-card{padding-bottom:40px}.picture-card:last-child{margin-bottom:unset}.picture-card__title{color:#fff;margin-bottom:6px}.picture-card__desc{color:#9ea4a7;font-size:14px;font-weight:300;display:flex;justify-content:space-between;margin-bottom:12px}.picture-card__stats__likes{padding-left:24px;margin-left:20px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:16px 16px;background-repeat:no-repeat;background-position:center left}.picture-card__stats__views{padding-left:24px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:16px 16px;background-repeat:no-repeat;background-position:center left}.picture-card__img-wrapper{background-color:#fff;padding:20px;border-radius:4px;height:auto}.picture-card__image{position:relative;width:100%}.picture-card__image__picture{width:100%;height:auto}.picture-card__image__loader{position:absolute;display:flex;justify-content:center;align-items:center;flex-direction:column;color:#fff;background-color:#4782ce;height:100%;width:100%;opacity:90%;transition:all 600ms ease;backdrop-filter:blur(5px)}.picture-card__image__loader.display{backdrop-filter:blur(5px);opacity:0}.picture-card__image__loader a{text-decoration:none}.picture-card__image__loader a:visited{text-decoration:none}", ""]);
// Exports
module.exports = exports;
