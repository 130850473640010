import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Preloader from '../../components/Preloader/preloader';
import Button from '../../components/Button/button';
import { delay } from '../../utils/utils';
import Viewer from './Viewer';
import finalObject from './objects';
import './index.scss';
var STATUS;
(function (STATUS) {
    STATUS["READY"] = "Ready!";
    STATUS["PRELOADING"] = "We are showing some animation";
    STATUS["LOADING"] = "Loading...";
    STATUS["WAITING"] = "Waiting for command";
})(STATUS || (STATUS = {}));
var getClasses = function (status) {
    var buttonClasses = classNames({
        'loading-button': true,
        'hidden': !status.isWaiting,
    });
    var canvasClasses = classNames({
        'canvas': true,
        'hidden': !status.isWaiting,
    });
    var unloadClasses = classNames({
        'button-unload': true,
        'visible': status.isReady,
    });
    var sceneClasses = classNames({
        'scene': true,
        'visible': status.isReady,
    });
    return {
        buttonClasses: buttonClasses,
        canvasClasses: canvasClasses,
        sceneClasses: sceneClasses,
        unloadClasses: unloadClasses,
    };
};
var getCurrentStatuses = function (status) {
    var isWaiting = status === STATUS.WAITING;
    var isReady = status === STATUS.READY;
    var isLoading = status === STATUS.LOADING;
    var isPreloading = status === STATUS.PRELOADING;
    return { isWaiting: isWaiting, isReady: isReady, isLoading: isLoading, isPreloading: isPreloading };
};
var Scene = function () {
    var _a = useState(STATUS.LOADING), status = _a[0], setStatus = _a[1];
    var statusStack = useMemo(function () { return getCurrentStatuses(status); }, [status]);
    var _b = useMemo(function () { return getClasses(statusStack); }, [statusStack]), buttonClasses = _b.buttonClasses, canvasClasses = _b.canvasClasses, unloadClasses = _b.unloadClasses, sceneClasses = _b.sceneClasses;
    var handleClick = useCallback(function () {
        setStatus(STATUS.PRELOADING);
        delay(200).then(function () { return setStatus(STATUS.LOADING); });
    }, [setStatus]);
    var viewer = useMemo(function () {
        if (statusStack.isLoading || statusStack.isReady) {
            return React.createElement(Viewer, { addHelpers: true, className: sceneClasses, object3D: finalObject, onSceneReady: function () { return setStatus(STATUS.READY); } });
        }
    }, [statusStack, sceneClasses]);
    return (React.createElement("div", { className: "particles-page" },
        React.createElement("div", { className: canvasClasses },
            React.createElement(Button, { className: buttonClasses, color: "white", onClick: function () { return handleClick(); }, style: "line" }, statusStack.isWaiting ? 'Load model' : ''),
            React.createElement(Preloader, { active: statusStack.isPreloading }),
            viewer),
        React.createElement("div", { className: "button-wrapper" },
            React.createElement(Button, { className: unloadClasses, onClick: function () { setStatus(STATUS.WAITING); } }, "Unload"))));
};
export default Scene;
