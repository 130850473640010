var Provider = /** @class */ (function () {
    function Provider(builder) {
        this.builder = builder;
    }
    Provider.prototype.getParts = function (amount) {
        var objects = [];
        for (var i = 0; i < amount; i++) {
            var part = this.builder();
            objects.push(part);
        }
        return objects;
    };
    return Provider;
}());
export default Provider;
