import { getRandomInt } from '../utils';
import * as THREE from 'three';
var PositioningMethodsPool = /** @class */ (function () {
    function PositioningMethodsPool() {
    }
    PositioningMethodsPool.prototype.randomizerMethod = function (options) {
        var max = options.max;
        var randomX = getRandomInt(max);
        var randomZ = getRandomInt(max);
        var x = randomX - max / 2;
        var z = randomZ - max / 2;
        var y = 0;
        return new THREE.Vector3(x, y, z);
    };
    PositioningMethodsPool.mutateOptions = function (options) {
        var countPerSide = Math.floor(Math.cbrt(options.count));
        var limit = countPerSide - 1;
        if (options.iteratorX > limit) {
            options.iteratorX = 0;
            options.iteratorZ++;
        }
        if (options.iteratorZ > limit) {
            options.iteratorZ = 0;
            options.iteratorY++;
        }
        if (options.iteratorY > limit) {
            options.iteratorY = 0;
        }
    };
    PositioningMethodsPool.cubesMethod = function (options) {
        if (!options)
            return new THREE.Vector3();
        options = options;
        PositioningMethodsPool.mutateOptions(options);
        var x = options.iteratorX * options.partCubeSize.x;
        var y = options.iteratorY * options.partCubeSize.y;
        var z = options.iteratorZ * options.partCubeSize.z;
        options.iteratorX++;
        return new THREE.Vector3(x, y, z);
    };
    return PositioningMethodsPool;
}());
export default PositioningMethodsPool;
