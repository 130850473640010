import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../../components/Button/button';
import { ReactSVG } from 'react-svg';
// @ts-ignore
import imgArrow from '../../assets/arrow.svg';
// @ts-ignore
import imgDribbbleLogo from '../../assets/dribbble-logo.svg';
import SidePanel from '../../components/SidePanel/sidePanel';
import useCanvas3d from './Canvas3D/useCanvas3d';
import ContactCard from './ContactCard';
import PicturesFeed from '../../components/PicturesFeed/picturesFeed';
import classNames from 'classnames';
import './index.scss';
var HomePage = function () {
    var _a = useState(false), sidePanelVisible = _a[0], setSidePanelVisible = _a[1];
    var _b = useState(false), isAnimation = _b[0], setIsAnimation = _b[1];
    var mountRef = useRef(null);
    useCanvas3d(mountRef);
    var hireBtnClasses = useMemo(function () {
        return (classNames({
            'hire-button': true,
            'animation': isAnimation,
        }));
    }, [isAnimation]);
    var runAnimation = useCallback(function () {
        setIsAnimation(true);
        setTimeout(function () { return setIsAnimation(false); }, 500);
    }, []);
    var dribblePortfolioTitle = useMemo(function () {
        return (React.createElement("div", { className: "flex vertical-align" },
            React.createElement(ReactSVG, { className: "inline margin-right__10", src: imgDribbbleLogo }),
            React.createElement("h2", { className: "white" }, "Dribbble Portfolio")));
    }, []);
    return (React.createElement("div", { className: "home-page" },
        React.createElement(SidePanel, { onHide: function () { return setSidePanelVisible(false); }, position: "left", title: dribblePortfolioTitle, visible: sidePanelVisible },
            React.createElement(PicturesFeed, null)),
        React.createElement("div", { className: "home-page__card-wrapper" },
            React.createElement(Button, { className: "portfolio-button", color: "blue", onClick: function () { return setSidePanelVisible(true); }, style: "line" },
                React.createElement(ReactSVG, { className: "icon-left", src: imgArrow }),
                "Portfolio"),
            React.createElement(Button, { className: hireBtnClasses, color: "blue", onClick: function () { return runAnimation(); }, style: "line" },
                "Hire me",
                React.createElement(ReactSVG, { className: "icon-right", src: imgArrow })),
            React.createElement(ContactCard, null),
            React.createElement("footer", { className: "copyright" },
                "\u00A9 ",
                new Date().getFullYear(),
                " Degtyarik")),
        React.createElement("div", { className: "my-canvas-3d", ref: mountRef })));
};
export default HomePage;
