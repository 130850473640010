import React from 'react';
import { getAvatar } from './utils';
import './index.scss';
var Canvas = function () {
    var size = 48;
    var list = ['Omar Garcia', 'Neil Griffith', 'Gerardo Rodriquez', 'Juan Lopez', 'Erik Valencia',
        'Francisco Chavez',
        'John Jorgensen', 'Miguel Bailon', 'rob Radusinovic', 'Edwin Menjivar', 'Matthew Ennis', 'Ever Cebrero',
        'Raymond Ortega', 'Ismael Lopez', 'Nicolas Placeholder', 'Roy McGuire', 'Bryan Cordon', 'Jose Gonzales',
        'Tim Colacito', 'Dan A', 'Jerry Wheaton', 'Abel Yanez', 'Efrain Montes', 'Luis Rojas', 'Gonzalo Rendon',
        'Dauren Chapayev', 'Carlos Mosqueda', 'Silvestre Meza', 'Julian Castillo', 'Fidel Calva', 'gee vang',
        'Antonio Carrasco', 'Carmen Contractor Mendez', 'Ricardo Oropesa', 'Mike Contractor Kreiser', 'Rudy Contractor',
        'Victor Santiago', 'Francisco Yanez', 'Sabina CONTRACTOR', 'Odysseas CONTRACTOR', 'Marvin Hernandez', 'David Marquez',
        'Misael Cruz', 'Alfredo Moreno', 'Elias Castillo', 'Manuel Ruiz', 'Bernardo Esquivel', 'Jesus Ramirez',
        'Andres Plascencia', 'Jose Villegas', 'Carlos Guillen', 'Genaro Frias', 'Jose Cruz', 'Pedro Galvez', 'Jose Garcia',
        'Ronnie Riofrio', 'Jaime Garcilazo', 'Celso Beltran', 'Francisco Javier', 'Alejandro Cervantes', 'Ernesto Navarrete',
        'Victor Saul', 'Alvaro Sandoval', 'Piri Hauling', 'Jesus Gonzalez', 'Herminio Sanchez', 'Eduardo Reyes',
        'Gildardo Portillo', 'A.S.A Drilling and Foundation Inc.', 'Pedro Maraver', 'Ricardo Rodriguez', 'Juan Enrique Lopez',
        'Abelardo Reyes', 'Christian Duarte', 'Manuela Moreno', 'Salvador Sajuan', 'Esteban Pacas', 'Euciel Romero',
        'Saul Diaz', 'Robert J Wachs', 'Jesus Uriel Ericon Valenzuela', 'Victor Armenta', 'Pablo Galeno', 'Felipe Huato',
        'Juan Ventura', 'Francisco Javier Ochoa', 'Oscar Carrillo', 'Ricardo Angeles Garcia', 'Alex Ramirez', 'Alberto Silva',
        'Juan Palomeque', 'Miguel Tobar', 'Octavio Maya', 'Jose A. Salas', 'Sergio Rodriguez', 'Jose Coronado',
        'Carlos Gallardo', 'Ruben Perez', 'Alfredo Mendez', 'Jesus Alberto Zepeda', 'Alvaro Meza Rivera',
        'Apolinar Hernandez Madrigal', 'Rogelio Farias', 'Tomas Jauregui', 'Elias Sandoval', 'Edgar Ivan Ochoa Gonzalez']
        .sort();
    return (React.createElement("div", { className: 'canvas', id: 'canvas=screen' }, list.map(function (item) { return (React.createElement("img", { className: "avatar", key: item, src: getAvatar(item, size, size) })); })));
};
export default Canvas;
