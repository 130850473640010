export var getAvatar = function (name, width, height) {
    var canvas = document.createElement('canvas');
    canvas.height = height;
    canvas.width = width;
    var ctx = canvas.getContext('2d');
    if (ctx) {
        var initials = getInitials(name);
        var grd = ctx.createLinearGradient(0, 2 * height, 0, -height);
        grd.addColorStop(0, stringToHslColor(name, 60, 50));
        grd.addColorStop(1, stringToHslColor(name, 50, 80));
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, width, height);
        ctx.font = Math.round(canvas.width / 2.2) + 'px Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.fillText(initials, width / 2, width / 1.5);
        return canvas.toDataURL();
    }
    else {
        throw new Error('Canvas context is not available');
    }
};
var stringToHslColor = function (str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) - ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(".concat(h, ", ").concat(s, "%, ").concat(l, "%)");
};
var getInitials = function (name) {
    var initials;
    if (name.includes(' ')) {
        initials = name.split(' ').map(function (part, index) {
            if (index < 2)
                return part[0];
        }).join('');
    }
    else {
        initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
};
