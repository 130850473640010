import * as THREE from 'three';
var Cube = /** @class */ (function () {
    function Cube() {
        this.model = this.provider();
        this.placer();
    }
    Cube.prototype.provider = function () {
        var geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
        var material = new THREE.MeshNormalMaterial();
        return new THREE.Mesh(geometry, material);
    };
    Cube.prototype.placer = function () {
        this.model.position.set(0, 0, 0);
    };
    Object.defineProperty(Cube.prototype, "object3d", {
        get: function () {
            return this.model;
        },
        enumerable: false,
        configurable: true
    });
    return Cube;
}());
export default Cube;
