import * as THREE from 'three';
export var createGridHelper = function (props) {
    if (props === void 0) { props = {}; }
    var _a = props.size, size = _a === void 0 ? 100 : _a, _b = props.divisions, divisions = _b === void 0 ? 100 : _b;
    var helper = new THREE.GridHelper(size, divisions);
    helper.castShadow = false;
    helper.receiveShadow = false;
    return helper;
};
export var createOrdHelper = function () {
    return new THREE.AxesHelper(5);
};
